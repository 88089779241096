import { InfoCircleOutlined } from "@ant-design/icons";
import { useLocation } from "@reach/router";
import { InputNumber, message, Spin, Tooltip } from "antd";
import { cloneDeep, set, toNumber } from "lodash";
import get from "lodash.get";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { feathers } from "../../../api/feathers_rest";
import { TimeoutClass } from "../../../class/TimeoutClass";
import { usdtTradingImg } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getAccountBalance from "../../../newApi/cxxt/getAccountBalance";
import getOrCreateRobotProfile from "../../../newApi/profile/getOrCreateRobotProfile";
import getProfiles from "../../../newApi/profile/getProfiles";
import enableProfileFundLimit from "../../../newApi/robots/enableProfileFundLimit";
import getRobotProfiles from "../../../newApi/robots/getRobotProfiles";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { numberDecimal } from "../../../utilities/startUp";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import RequiredApiCheck from "../../general/components/RequiredApiCheck";
import HighFrequencyForm3 from "../components/HighFrequencyForm3";
import editCapitalAmount from "../../../newApi/profile/editCapitalAmount";
import { robotStrategyDataKey } from "../../../utilities/data";

// markup
const tooltipTextColor = "#00d928";

const RobotPage = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  // const [viewVisible, setViewVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [walletLimit, setWalletLimit] = useState(0);
  const [capitalAmount, setCapitalAmount] = useState(0);
  const [profile, setProfile] = useState({});
  const [robotProfile, setRobotProfile] = useState({});
  // const [type, setType] = useState("1");
  const [platform, setPlatform] = useState();
  const [keyPassed, setKeyPassed] = useState();
  const [exchangeAccountBalance, setExchangeAccountBalance] = useState({});
  const [robotProfileId, setRobotProfileId] = useState();
  let exchangeBalance = get(exchangeAccountBalance, "USDT.free") || 0;
  const accessKey = get(props.user, "accessKey");

  message.config({
    duration: 2,
  });
  useEffect(() => {
    let query = queryString.parse(location.search);
    // setType(get(query, "type"));
    setPlatform(get(query, "platform"));
    getData();
  }, []);

  useEffect(() => {
    if (platform) {
      getRobotProfile.run(get(props.user, "user.profile._id"), platform);
    }
  }, [platform, props.user]);

  useEffect(() => {
    if (robotProfileId && keyPassed) {
      getAccountBalanceAPI.run(robotProfileId);
      getRobotProfilesData.run(robotProfileId);
    }
  }, [robotProfileId, keyPassed]);

  let getRobotProfile = new TimeoutClass(function getRobotProfile(
    profileId,
    usedFor
  ) {
    getOrCreateRobotProfile({
      usedFor: usedFor,
      exchange: usedFor,
      profileId,
    })
      .then((res) => {
        if (get(res, "data.robotProfileId")) {
          setRobotProfileId(get(res, "data.robotProfileId"));
        } else {
          setRobotProfileId(get(res, "data._id"));
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(err.message);
      });
  });

  let getAccountBalanceAPI = new TimeoutClass(function getAccountBalanceAPI(
    robotProfileId
  ) {
    // console.log({ robotProfileId });
    getAccountBalance(
      {
        robotProfileId,
      },
      accessKey
    )
      .then((res) => {
        // console.log({ res });
        setExchangeAccountBalance(get(res, "data"));
      })
      .catch((err) => {
        console.log(err);
        message.error(err.message);
      });
  },
  1000);

  let getRobotProfilesData = new TimeoutClass(function getRobotProfilesData(
    robotProfileId
  ) {
    // console.log({ robotProfileId });
    getRobotProfiles({
      _id: robotProfileId,
    })
      .then((res) => {
        // console.log({ res });
        setRobotProfile(get(res, "data[0]"));
      })
      .catch((err) => {
        console.log(err);
        message.error(err.message);
      });
  },
  1000);

  // console.log("robot profile", robotProfile);
  useEffect(() => {
    if (!editMode) {
      setWalletLimit(get(robotProfile, "robotFundLimit.highFrequency.limit"));
      setCapitalAmount(
        get(profile, `wallet.highFrequency.capital.${platform}`) || 0
      );
    }
  }, [robotProfile, editMode]);

  function getData() {
    getProfiles(1, 0, { _id: get(props.user, "user.profile._id") })
      .then((res) => {
        setProfile(get(res, "data[0]") || {});
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleSubmit() {
    let balance = 0;
    let used =
      toNumber(get(robotProfile, "robotFundLimit.highFrequency.used")) || 0;
    let originLimit =
      toNumber(get(robotProfile, "robotFundLimit.highFrequency.limit")) || 0;
    if (walletLimit !== originLimit && walletLimit > exchangeBalance + used) {
      message.error(
        <>
          {/* 币安余额不足  */}
          {/* <br />  */}
          {t("insufficientBalance", sourceKey.highFrequency)}
        </>,
        2
      );
      return;
    }
    if (capitalAmount <= 0) {
      message.error(
        <>
          {/* 资本金不能小于0 
          <br />  */}
          {t("capitallSmall", sourceKey.highFrequency)}
        </>,
        2
      );
      return;
    }
    balance = walletLimit - used;
    let newWalletValue = cloneDeep(get(profile, "wallet"));

    set(newWalletValue, `highFrequency.capital.${platform}`, capitalAmount);

    if (walletLimit !== originLimit) {
      set(newWalletValue, `highFrequency.limit.${platform}`, walletLimit);
      enableProfileFundLimit({
        profileId: robotProfileId,
        limit: walletLimit,
        strategy: "highFrequency",
      })
        .then((res) => {
          // console.log(res);
          message.success(t("changedLimit", sourceKey.highFrequency), 2);
          getRobotProfilesData.run(robotProfileId);
        })
        .catch((err) => {
          message.error(err?.message);
          console.log(err);
        });
    }

    editCapitalAmount({
      profileId: profile?._id,
      strategy: robotStrategyDataKey.usdt,
      capitalAmount: capitalAmount,
      limitAmount: walletLimit,
      platform: platform,
    })
      .then((res) => {
        if (walletLimit === originLimit) {
          message.success(t("success"));
        }
        setProfile(res?.data?.profile || {});
        setEditMode(false);
      })
      .catch((err) => {
        message.error(err?.message, 1.5);
        console.log(err);
      });
  }

  return (
    <React.Fragment>
      <RequiredApiCheck
        robotProfileId={robotProfileId}
        onPassed={() => {
          setKeyPassed(true);
        }}
      />
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            suffix={
              <React.Fragment>
                {/* <div className="justify-between flex items-center pt-3">
                                <div className="flex text-base font-semibold items-center">
                                    <span className='inline-block text-base font-semibold' >
                                        高频 High Frequency

                                    </span>
                                </div>
                                 <ReconciliationOutlined
                                className="black  mx-2"
                                style={{ fontSize: 23 }}
                                onClick={() => navigate(routes.highFrequencySimulator.to({ type: 3 }))}
                            />
                            </div> */}
                {/* {type === "3" && (
                  <div className="flex justify-end items-center mt-3">
                    <LineChartOutlined
                      style={{ color: "black", fontSize: "25px" }}
                      className=""
                    />
                    <Button
                      type="text"
                      onClick={() =>
                        navigate(routes.highFrequencySimulator.to({ type: 3 }))
                      }
                    >
                      <span
                        className="text-xs underline"
                        style={{ fontSize: 14 }}
                      >
                        Simulator
                      </span>
                    </Button>
                  </div>
                )} */}
              </React.Fragment>
            }
            showBack={true}
          >
            {/* <div className="flex justify-between mx-4 mb-2">
              <div className="flex">
                <img src={usdtTradingImg} width="50.51px" height="50.51px" />
                <span className="text-lg text-center pt-2.5 pl-2 font-bold">
                  {t("usdtITS", sourceKey.highFrequency)}
                </span>
              </div>
            
            </div> */}
            <div className="rounded-3xl bg-main-color-gradient box-shadow-light px-5 py-3 mx-4">
              <div className="mt-1 flex justify-between px-2">
                <span className="inline-block">
                  <div className=" font-light font-bold text-left">
                    {t("initiatedCapital", sourceKey.highFrequency)}
                  </div>
                  {editMode ? (
                    <div className="">
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={t(
                          "initiatedCapital",
                          sourceKey.highFrequency
                        )}
                        autoFocus
                        size="large"
                        max={999999999999999}
                        value={capitalAmount}
                        onChange={(e) => {
                          setCapitalAmount(e);
                        }}
                      />
                    </div>
                  ) : (
                    <div className=" font-bold">
                      {formatDecimalNumber(
                        get(
                          profile,
                          `wallet.highFrequency.capital.${platform}`
                        ) || 0,
                        numberDecimal
                      )}{" "}
                      <span style={{ fontSize: "10px" }}>USDT</span>
                    </div>
                  )}
                </span>
                <div className="flex">
                  {editMode ? (
                    <div className="">
                      <div
                        className="cursor-pointer font-bold"
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        {t("submit")}
                      </div>
                      <div
                        className=" text-red-400 pt-1 cursor-pointer"
                        onClick={() => {
                          setEditMode(false);
                        }}
                      >
                        {t("cancel")}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="cursor-pointer font-bold"
                      onClick={() => {
                        setEditMode(true);
                      }}
                    >
                      {t("edit")}
                    </div>
                  )}
                </div>
              </div>
              <div className=" flex justify-center items-center mt-4">
                <span
                  className="inline-block w-1/3 mr-2 rounded-2xl"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                  }}
                >
                  {editMode ? (
                    <div className="my-2">
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="账户额度 Wallet Limit"
                        autoFocus
                        size="large"
                        value={walletLimit}
                        onChange={(e) => {
                          setWalletLimit(e);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="text-base font-semibold text-center">
                      {formatDecimalNumber(
                        get(
                          robotProfile,
                          "robotFundLimit.highFrequency.limit"
                        ) || 0,
                        numberDecimal
                      )}
                      <br />
                      {/* USDT */}
                    </div>
                  )}
                  <div className="text-xs font-light  text-center flex justify-center">
                    {/* 账户额度 <br /> Wallet Limit */}
                    {t("walletLimit", sourceKey.highFrequency)}
                    <Tooltip
                      className="text-s pl-1 pt-1"
                      overlayStyle={{ whiteSpace: "pre-line" }}
                      trigger="click"
                      placement="bottom"
                      title={
                        <div
                          style={{
                            color: tooltipTextColor,
                          }}
                        >
                          <div>{t("limitDesc1", sourceKey.highFrequency)}</div>
                          <div>{t("limitDesc2", sourceKey.highFrequency)}</div>
                        </div>
                      }
                    >
                      <InfoCircleOutlined className="" />
                    </Tooltip>
                  </div>
                </span>
                <span
                  className="inline-block w-1/3 mr-2 rounded-2xl"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                  }}
                >
                  <div className="text-base font-semibold  text-center">
                    {formatDecimalNumber(
                      get(robotProfile, "robotFundLimit.highFrequency.used") ||
                        0,
                      numberDecimal
                    )}{" "}
                    <br />
                    {/* USDT */}
                  </div>
                  <div className="text-xs font-light  text-center">
                    {/* 已购买额度 <br /> Used Limit */}
                    {t("usedLimit", sourceKey.highFrequency)}
                  </div>
                </span>

                <span
                  className="inline-block w-1/3 mr-2 rounded-2xl"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                  }}
                >
                  <div className="text-base font-semibold  text-center">
                    {formatDecimalNumber(exchangeBalance || 0, numberDecimal)}{" "}
                    <br />
                    {/* USDT */}
                  </div>
                  <div className="text-xs font-light  text-center">
                    {/* 交易平台钱包 <br /> Exchange Wallet */}
                    {t("exchangeWallet", sourceKey.highFrequency)}
                  </div>
                </span>
              </div>

              {/* {editMode ? (
                <div className="grid grid-cols-4 gap-2 items-center mt-7">
                  <div className="col-span-2">
                    <Button
                      className="text-xs rounded input-border mr-2"
                      onClick={() => {
                        setEditMode(false);
                      }}
                      style={{
                        width: "100%",
                        backgroundColor: "red",
                        // color: "white"
                      }}
                    >
                      <span className="text-xs text-white">{t("cancel")}</span>
                    </Button>
                  </div>

                  <div className="col-span-2">
                    <Button
                      className="text-xs rounded main-button-color input-border"
                      onClick={() => {
                        handleSubmit();
                      }}
                      style={{ width: "100%" }}
                    >
                      {t("change")}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center mt-7">
                  <Button
                    className="text-xs rounded main-button-color input-border"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setEditMode(true);
                    }}
                  >
                    {t("changeLimit", sourceKey.highFrequency)}
                  </Button>
                </div>
              )} */}
              {/* </MediaQuery> */}
            </div>
            <div className="px-4 pt-1.5">
              <div className="px-5">
                <HighFrequencyForm3
                  profile={profile}
                  robotProfile={robotProfile}
                  platform={platform}
                />
              </div>
            </div>
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(RobotPage);

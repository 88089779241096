import { useLocation } from "@reach/router";
import { message } from "antd";
import { navigate } from "gatsby";
import { get, isEmpty } from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import checkAuthentication from "../../../newApi/cxxt/checkAuthentication";
import { routes } from "../../../route";
import checkExchangeApiKey from "../../../newApi/cxxt/checkExchangeApiKey";

// markup
const RequireApiCheck = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const accessKey = get(props.user, "accessKey");

  useEffect(() => {
    if (get(location, "pathname") != routes.apiKey.to()) {
      const isAuthenticated = get(props, "user.isAuthenticated");
      let profile = get(props.user, "user.profile");
      if (!isAuthenticated && typeof window !== `undefined`) {
        navigate(routes.login.to(), {
          state: {
            hideBack: true,
          },
        });
        // message.error('Please Login to proceed');
        message.error(t("loginProceed", sourceKey.api));

        return <></>;
      }
      if (!isEmpty(props.robotProfileId)) {
        // console.log("checking", props.robotProfileId);
        checkExchangeApiKey({ profileId: props.robotProfileId }, accessKey)
          .then((res) => {
            // console.log({ res });
            if (props.onPassed && get(res, "data.isAuthenticated")) {
              props.onPassed();
            }

            if (!get(res, "data.isAuthenticated")) {
              if (props.onFailed) {
                props.onFailed();
              } else {
                message.error(t("invalidApiKey", sourceKey.api));
                navigate(routes.apiKeyListing.to());
              }
            }
          })
          .catch((err) => {
            console.log(err);
            navigate(routes.apiKeyListing.to());
            // message.error(err.message);
            message.error(t("invalidApiKey", sourceKey.api));
          });
      }
    }
  }, [props.robotProfileId]);

  return <React.Fragment>{props.children}</React.Fragment>;
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(RequireApiCheck);

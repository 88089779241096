import { isPlainObject } from "lodash";
import { ccxtApiPrefix } from ".";
import { apiUrl, _base_axios_get } from "..";

export default async function getAccountBalance(query = {}, accessKey = "") {
  if (!isPlainObject(query)) {
    query = {};
  }

  return await _base_axios_get(
    `${apiUrl}/${ccxtApiPrefix}/getAccountBalance`,
    query,
    accessKey
  );
}

import { isPlainObject } from "lodash";
import { ccxtApiPrefix } from ".";
import { apiUrl, _base_axios_post } from "..";

export default async function checkExchangeApiKey(query = {}, accessKey = "") {
  if (!isPlainObject(query)) {
    query = {};
  }

  return await _base_axios_post(
    `${apiUrl}/${ccxtApiPrefix}/checkApiKey`,
    query,
    accessKey
  );
}
